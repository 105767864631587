<template>
  <b-card-code>
       <div class="d-flex justify-content-end">
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <validation-observer ref="AddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="add">
        <b-form-group label="Organization" label-for="organization Name">
          <validation-provider
            #default="{ errors }"
            name="organization"
            rules="required"
          >
            <!-- <b-form-select
              class="w-100"
              v-model="organization"
              :options="organizations"
              @change="loadOrgUsers"
            >
            </b-form-select> -->

            <v-select
              v-if="is_staff"
              v-model="organization"
              label="name"
              placeholder="--Select Organization--"
              :options="organizations"
              @input="loadOrgUsers"
              autocomplete
              style="width: 100%"
              :reduce="(organization) => organization.tenant_uid"
              disabled
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- {{organization}} -->
        </b-form-group>
        <b-form-group label="Notify Users">
          <!-- <b-form-select class="" v-model="notifyUser" :options="users" multiple :select-size="4">
          </b-form-select> -->
          <v-select
            v-model="notifyUser"
            multiple
            :options="users"
            label="text"
            taggable
          />
        </b-form-group>
        <!-- {{ users }} -->

        <b-form-group label="Report Name" label-for="File Name">
          <template #label>
            <span>Report Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="file Name"
            :rules="{
              required: true,
              regex: /^[a-zA-Z0-9\s\-\_\(\)\ [\]\ {\} \|\/ ]+$/,
            }"
          >
            <b-form-input
              id="file_name"
              v-model="file_name"
              :state="errors.length > 0 ? false : null"
              name="file_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- <p style="font-size: 10px;"><span class="text-danger">*</span>Please add .doc extension with name</p> -->
        </b-form-group>

        <!--Upload File -->
        <b-form-group label="Upload File" label-for="File">
          <template #label>
            <span>Upload File</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="file"
            rules="required|ext:doc,docx,csv,pdf,xlsx,xls,zip"
          >
            <b-form-file
              v-model="doc_file"
              :state="Boolean(doc_file)"
              ref="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              v-on:change="handleFileUpload()"
            ></b-form-file>
            <!-- <input type="file" @change="filesChange()" ref="file" /> -->

            <div class="mt-1">
              Selected file: {{ doc_file ? doc_file.name : "" }}
            </div>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- version -->
        <b-form-group label="Version" label-for="Version Name">
          <template #label>
            <span>Version</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="version"
            :rules="{
              required: true,
              regex: /^[a-zA-Z0-9\s\.\/\-\_\(\)\ [\]\ {\} \|]+$/,
            }"
          >
            <b-form-input
              id="version"
              v-model="version"
              :state="errors.length > 0 ? false : null"
              name="version"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid | disable"
          class="float-right"
          size="sm"
        >
          <span v-if="isLoading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Upload</span>
        </b-button>
        <b-button
          @click="publishReport()"
          variant="primary"
          :disabled="invalid | disable"
          class="float-right mr-1"
          size="sm"
        >
          <span v-if="isLoadingPublic"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Upload & Publish Report</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
    BSpinner
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      report_name: "{{report_name}}",
      is_staff: this.$store.state.app.user.is_staff,
      super_admin: this.$store.state.app.user.id == 1,
      doc_file: null,
      version: "",
      file_name: "",
      selectedOrganization: this.$store.state.app.org_id,
      organizations: this.$store.state.app.workspaces,
      // organization: null,
      // organizations: [],
      user: "",
      notifyUser: "",
      users: [],
      isLoading: false,
      disable: false,
      isLoadingPublic:false
    };
  },
  computed: {
    organization() {
      return this.organizations.find(
        (org) => org.tenant_uid === this.$store.state.app.org_id
      );
    },
    selectedOrganizationRefid() {
      if (!this.super_admin) {
        return this.$store.state.app.org_id;
      } else if (this.organization) {
        return this.organization.tenant_uid;
      }
      return null;
    },
    selectedOrganizationId() {
      if (!this.super_admin) {
        return this.$store.state.app.organizationId;
      } else if (this.organization) {
        return this.$store.state.app.organizationId;
        // return this.organization.id;
      }
      return null; // Handle the case when organization is not selected
    },
  },
  created: function () {
    //  this.load(); --> not calling this due page load issues
    // this.load();
    if (this.selectedOrganizationRefid) {
      this.loadOrgUsers();
    }
  },
  methods: {
    // load: function () {
    //   this.organizations = [];

    //   const o_options = {
    //     method: "GET",
    //     headers: { "content-type": "application/json" },
    //     url:
    //       process.env.VUE_APP_BASEURL + "organization/organization/get-all-org",
    //   };
    //   var self = this;
    //   this.$http(o_options).then((res) => {

    //     res.data.map(function (value, key) {
    //       let org = {
    //         value: res.data[key].sso_ref_id,
    //         text: res.data[key].org_name,
    //       };
    //       self.organizations.push(org);
    //     });
    //   });
    // },

    // filesChange() {
    //   this.doc_file = this.$refs.file.files[0];
    // },

    add: function () {
      this.$refs.AddForm.validate().then((success) => {
        this.disable = true;
        this.isLoading = true;
        if (success) {
          console.log(this.notifyUser, "dddddddddd");
          console.log(this.notifyUser[0], "arraaaaaaaa");
          let formData = new FormData();
          formData.append("doc_file", this.doc_file);
          formData.append("report_name", this.file_name);
          // formData.append("report_filename", this.file_name);
          formData.append("version", this.version);
          formData.append("org_id", this.selectedOrganizationId);
          formData.append("notify_users", JSON.stringify(this.notifyUser));
          console.log(this.notifyUser, "dddddddddd");
          console.log(this.notifyUser[0], "arraaaaaaaa");
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: formData,
            url:
              process.env.VUE_APP_BASEURL +
              "report/report/upload-generated-report/",
          };
          this.$http(options).then((res) => {
            if (res.data.status === "success") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.push({ name: "List Reports" });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
            this.disable = false;
            this.isLoading = false;
          });
        }
      });
    },
    publishReport: function () {
      this.disable = true;
      this.isLoadingPublic = true;
      console.log(this.notifyUser, "dddddddddd");
      console.log(this.notifyUser[0], "arraaaaaaaa");
      let formData = new FormData();
      formData.append("doc_file", this.doc_file);
      formData.append("report_name", this.file_name);
      // formData.append("report_filename", this.file_name);
      formData.append("version", this.version);
      formData.append("org_id", this.selectedOrganizationId);
      formData.append("notify_users", JSON.stringify(this.notifyUser));
      console.log(formData, "formadatataaaaaaaaa");
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: formData,
        url:
          process.env.VUE_APP_BASEURL +
          "report/report/upload&publish-generated-report/",
      };
      this.$http(options).then((res) => {
        if (res.data.status === "success") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$router.push({ name: "List Reports" });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.disable = false;
        this.isLoadingPublic= false;
      });
    },
    loadOrgUsers: function () {
      this.users = [];
      // Load User drop down
      let url =
        process.env.VUE_APP_BASEURL + "user/users/get-all-org-users?query=true";

      if (this.organization != null) {
        if (this.organization == "system") {
          url = url + "&role_type=" + 0;
        } else {
          url = url + "&q_org_id=" + this.selectedOrganizationRefid;
        }
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;

      this.$http(o_options).then((res) => {
        res.data.map(function (value, key) {
          let notifyUser = {
            value: res.data[key].id,
            text: res.data[key].first_name,
          };
          self.users.push(notifyUser);
        });
      });
    },

    handleFileUpload() {
      this.doc_file = this.$refs.file.files[0];
    },
     goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style  lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>