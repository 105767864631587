var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v("Back")])],1),_c('validation-observer',{ref:"AddForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.add($event)}}},[_c('b-form-group',{attrs:{"label":"Organization","label-for":"organization Name"}},[_c('validation-provider',{attrs:{"name":"organization","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.is_staff)?_c('v-select',{staticStyle:{"width":"100%"},attrs:{"label":"name","placeholder":"--Select Organization--","options":_vm.organizations,"autocomplete":"","reduce":function (organization) { return organization.tenant_uid; },"disabled":""},on:{"input":_vm.loadOrgUsers},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Notify Users"}},[_c('v-select',{attrs:{"multiple":"","options":_vm.users,"label":"text","taggable":""},model:{value:(_vm.notifyUser),callback:function ($$v) {_vm.notifyUser=$$v},expression:"notifyUser"}})],1),_c('b-form-group',{attrs:{"label":"Report Name","label-for":"File Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Report Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"file Name","rules":{
            required: true,
            regex: /^[a-zA-Z0-9\s\-\_\(\)\ [\]\ {\} \|\/ ]+$/,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"file_name","state":errors.length > 0 ? false : null,"name":"file_name"},model:{value:(_vm.file_name),callback:function ($$v) {_vm.file_name=$$v},expression:"file_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Upload File","label-for":"File"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Upload File")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"file","rules":"required|ext:doc,docx,csv,pdf,xlsx,xls,zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-file',{ref:"file",attrs:{"state":Boolean(_vm.doc_file),"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.handleFileUpload()}},model:{value:(_vm.doc_file),callback:function ($$v) {_vm.doc_file=$$v},expression:"doc_file"}}),_c('div',{staticClass:"mt-1"},[_vm._v(" Selected file: "+_vm._s(_vm.doc_file ? _vm.doc_file.name : "")+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Version","label-for":"Version Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Version")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"version","rules":{
            required: true,
            regex: /^[a-zA-Z0-9\s\.\/\-\_\(\)\ [\]\ {\} \|]+$/,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"version","state":errors.length > 0 ? false : null,"name":"version"},model:{value:(_vm.version),callback:function ($$v) {_vm.version=$$v},expression:"version"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","variant":"primary","disabled":_vm._f("disable")(invalid),"size":"sm"}},[(_vm.isLoading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v("Upload")])]),_c('b-button',{staticClass:"float-right mr-1",attrs:{"variant":"primary","disabled":_vm._f("disable")(invalid),"size":"sm"},on:{"click":function($event){return _vm.publishReport()}}},[(_vm.isLoadingPublic)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v("Upload & Publish Report")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }